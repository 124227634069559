<template>
  <div class="order">
    <!-- <div class="mantil">优惠券</div> -->
    <!-- 缩宽90% -->
    <div class="orders">
      <div class="basicsName">
        <div class="basicsNames">
          <div
            class="basicsNamesTop"
            @click="navChange(0, 1)"
            :class="[
              couponType == 1 && orderStates == 0 ? 'basicsNamesTops' : '',
            ]"
          >
            无门槛券
          </div>
          <div
            class="basicsNamesCen"
            v-show="couponType == 1 && orderStates == 0"
          ></div>
        </div>
        <div class="basicsNames">
          <div
            class="basicsNamesTop"
            @click="navChange(0, 2)"
            :class="[
              couponType == 2 && orderStates == 0 ? 'basicsNamesTops' : '',
            ]"
          >
            满减券
          </div>
          <div
            class="basicsNamesCen"
            v-show="couponType == 2 && orderStates == 0"
          ></div>
        </div>
        <div class="basicsNames">
          <div
            class="basicsNamesTop"
            @click="navChange(0, 3)"
            :class="[
              couponType == 3 && orderStates == 0 ? 'basicsNamesTops' : '',
            ]"
          >
            折扣券
          </div>
          <div
            class="basicsNamesCen"
            v-show="couponType == 3 && orderStates == 0"
          ></div>
        </div>

        <div class="basicsNames">
          <div
            class="basicsNamesTop"
            @click="navChange(1, null)"
            :class="[
              couponType == null && orderStates == 1 ? 'basicsNamesTops' : '',
            ]"
          >
            已使用
          </div>
          <div
            class="basicsNamesCen"
            v-show="couponType == null && orderStates == 1"
          ></div>
        </div>
        <div class="basicsNames">
          <div
            class="basicsNamesTop"
            @click="navChange(2, null)"
            :class="[
              couponType == null && orderStates == 2 ? 'basicsNamesTops' : '',
            ]"
          >
            已过期
          </div>
          <div
            class="basicsNamesCen"
            v-show="couponType == null && orderStates == 2"
          ></div>
        </div>
        <div class="basicsNames">
          <div
            class="basicsNamesTop"
            @click="navChange(0, null)"
            :class="[
              couponType == null && orderStates == 0 ? 'basicsNamesTops' : '',
            ]"
          >
            领券中心
          </div>
          <div
            class="basicsNamesCen"
            v-show="couponType == null && orderStates == 0"
          ></div>
        </div>
      </div>
      <!-- 优惠券列表 -->
      <div class="tabCar-box">
        <div
          class="tabCar"
          v-if="
            orderStates == 0 &&
            couponType == null &&
            yhqlist &&
            yhqlist.length > 0
          "
        >
          <div
            class="Carlist ss"
            v-for="(itemx, index) in yhqlist"
            :key="index"
          >
            <div>
              <div class="CarListContent">
                <div class="money">
                  <div class="moneys" v-if="itemx.couponType == 1 || itemx.couponType == 2">
                    <span class="moneyLog">￥</span> {{ itemx.couponValue }}
                  </div>
                  <div class="moneys" v-if="itemx.couponType == 3">
                    <!-- <span class="moneyLog"></span> --> 
                    {{ itemx.fullDiscountRate / 10 }}
                    <span class="fz-14">折</span>
                  </div>

                  <div class="qlis">
                    <!-- <div class="text" v-if="itemx.applicableGoods == 2">
                      限定商品可用
                    </div> -->
                    <div class="text mb-2" v-if="itemx.couponType == 1">无门槛券</div>
                    <div class="text mb-2" v-if="itemx.couponType == 2">满减券</div>
                    <div class="text mb-2" v-if="itemx.couponType == 3">折扣券</div>
                  </div>
                </div>
                <img class="xuxian" src="@/assets/img/user/xuxian.png" alt="" />
                <div class="content conflex">
                  <div class="text-box">
                    <div class="title elp til_top">
                      <div class="til_m">{{ itemx.couponName }}</div>
                    </div>
                    <div class="time m-t-14">
                      <!-- 无门槛券 -->
                      <div class="fifday"></div>
                      <!-- 满减券 -->
                      <div class="mb-2" v-if="itemx.couponType == 2">
                      </div>
                      <div class="mb-2" v-if="itemx.forPeople == 4 && itemx.limitDaysForOlder">
                        <div>{{`注册满${itemx.limitDaysForOlder}天后可领取`}}</div>
                      </div>
                      <div class="mb-2" v-if="itemx.forPeople == 5 && !itemx.limitLowAmount">
                        <div>首次下单完成后可领取</div>
                      </div>
                      <div class="mb-2" v-if="itemx.forPeople == 5 && itemx.limitLowAmount">
                        <div>{{`首次下单满${itemx.limitLowAmount}元可领取`}}</div>
                      </div>
                      <div class="mb-2" v-if="itemx.forPeople == 6 && itemx.limitLowAmount">
                        <div>{{`累计消费满${itemx.limitLowAmount}元可领取`}}</div>
                      </div>
                      <div class="mb-2" v-if="itemx.forPeople == 6 && !itemx.limitLowAmount">
                        <div>{{`累计消费满${itemx.limitLowAmount}元可领取`}}</div>
                      </div>
                      <div class="mb-2" v-if="itemx.forPeople == 3">
                        <div>{{`新用户可领取`}}</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div v-if="itemx.status != 2" class="getQ" @click="getquan(itemx)">领取</div>
                    <div v-else class="getQ">已领取</div>
                    <div class="arrow" @click="rulesOfUseBtn(itemx)">
                      <img class="arrowImg" src="@/assets/img/Home/Group 381.png">
                    </div>
                  </div>
                </div>
              </div>
              <div class="rulesOfUseBox">
                <div class="rulesOfUse" v-show="itemx.rules">
                  <!-- 无门槛券 -->
                  <!-- <div v-if="itemx.couponType == 1 && !itemx.applicableGoodsSet">
                    {{ `满${itemx.fullReductionAmount}元可用` }}
                  </div> -->
                  <div v-if="itemx.couponType == 1 && itemx.applicableGoodsName && itemx.fullReductionAmount">
                    {{ `满${itemx.fullReductionAmount}元可用，只能用于购买${itemx.applicableGoodsName}` }}
                  </div>
                  <div v-if="itemx.couponType == 1 && itemx.applicableGoodsName">
                    {{ `只能用于购买${itemx.applicableGoodsName}` }}
                  </div>
                  <!-- 满减券 -->
                  <div v-else-if="itemx.couponType == 2 && itemx.fullReductionAmount && !itemx.applicableGoodsName">
                    {{ `满${itemx.fullReductionAmount}元可用` }}
                  </div>
                  <div v-else-if="itemx.couponType == 2 && itemx.fullReductionAmount && itemx.applicableGoodsName">
                    {{ `满${itemx.fullReductionAmount}元可用，只能用于购买${itemx.applicableGoodsName}` }}
                  </div>
                  <!-- 折扣券 -->
                  <div v-else-if="itemx.couponType == 3 && itemx.fullReductionAmount && !itemx.applicableGoodsName">
                    {{ `满${itemx.fullReductionAmount}元可用` }}
                  </div>
                  <div v-else-if="itemx.couponType == 3 && itemx.fullDiscountRate && itemx.applicableGoodsName">
                    {{ `满${itemx.fullReductionAmount}元可用，只能用于购买${itemx.applicableGoodsName}` }}
                  </div>
                  <div v-else>
                    {{ `适用于全部商品` }}
                  </div>
                  <div>使用规则</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tabCar" v-else-if="list && list.length > 0">
          <div
            class="Carlist"
            v-for="(item, index) in list"
            :key="index"
            :class="
              orderStates == 2 ? 'Expired' : orderStates == 1 ? 'usered' : ''
            "
          >
            <div>
              <div
                :class="
                  orderStates == 0
                    ? 'CarListContent'
                    : orderStates == 1
                    ? 'CarListContents'
                    : 'CarListContentss'
                "
              >
                <div class="money">
                  <div class="moneys" v-if="item.couponType == 1 || item.couponType == 2">
                    <span class="moneyLog">￥</span> {{ item.couponValue }}
                  </div>
                  <div class="moneys" v-if="item.couponType == 3">
                    {{ item.fullDiscountRate / 10 }}
                    <span class="fz-14">折</span>
                  </div>

                  <div class="qlis">
                    <div v-if="item.couponType == 3" class="text">折扣券</div>
                    <div v-if="item.couponType == 2" class="text">满减券</div>
                    <div v-if="item.couponType == 1" class="text mb-2">无门槛券</div>
                    <!-- <div class="text " v-if="item.applicableGoods == 2">
                      限定商品可用
                    </div> -->
                  </div>
                </div>
                <img class="xuxian" src="@/assets/img/user/xuxian.png" alt="" />
                <div class="content">
                  <div class="title elp">
                    <div class="til_m">{{ item.couponName }}</div>
                  </div>
                  <div class="time m-t-14">
                    <!-- 无门槛券 -->
                    <div class="fifday"></div>
                    <!-- 满减券 -->
                    <div v-if="item.couponType == 2">
                      <div class="text elp">
                        满{{ item.fullReductionAmount }}元减{{
                          item.couponValue
                        }}元
                      </div>
                      <div class="text elp">
                        
                      </div>
                    </div>
                  </div>
                  <div class="time elp">有效期至{{ item.validEndTime }}</div>
                  <div class="forPeople">{{ item.forPeople == 3 || item.forPeople == 4 ? forPeople[item.forPeople] + '专享' : '' }}</div>
                </div>
                <div v-if="orderStates != 1 && orderStates != 2" class="arrowBox">
                  <div class="arrow" @click="rulesOfUseBtn(item)">
                      <img class="arrowImg" src="@/assets/img/Home/Group 381.png">
                    </div>
                </div>
              </div>
              <div class="rulesOfUseBox">
                <div class="rulesOfUse" v-show="item.rules">
                  <!-- 无门槛券 -->
                  <!-- <div v-if="item.couponType == 1 && !item.applicableGoodsSet">
                    {{ `满${item.fullReductionAmount}元可用` }}
                  </div> -->
                  <div v-if="item.couponType == 1 && item.applicableGoodsName && item.fullReductionAmount">
                    {{ `满${item.fullReductionAmount}元可用，只能用于购买${item.applicableGoodsName}` }}
                  </div>
                  <div v-if="item.couponType == 1 && item.applicableGoodsName">
                    {{ `只能用于购买${item.applicableGoodsName}` }}
                  </div>
                  <!-- 满减券 -->
                  <div v-else-if="item.couponType == 2 && item.fullReductionAmount && !item.applicableGoodsName">
                    {{ `满${item.fullReductionAmount}元可用` }}
                  </div>
                  <div v-else-if="item.couponType == 2 && item.fullReductionAmount && item.applicableGoodsName">
                    {{ `满${item.fullReductionAmount}元可用，只能用于购买${item.applicableGoodsName}` }}
                  </div>
                  <!-- 折扣券 -->
                  <div v-else-if="item.couponType == 3 && item.fullReductionAmount && !item.applicableGoodsName">
                    {{ `满${item.fullReductionAmount}元可用` }}
                  </div>
                  <div v-else-if="item.couponType == 3 && item.fullDiscountRate && item.applicableGoodsName">
                    {{ `满${item.fullReductionAmount}元可用，只能用于购买${item.applicableGoodsName}` }}
                  </div>
                  <div v-else>
                    {{ `适用于全部商品` }}
                  </div>
                  <div>使用规则</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="isNull" v-if="!list || (list && list.length == 0) || !yhqlist || yhqlist.length == 0">
        <div class="text">暂无优惠劵哦~</div>
      </div> -->
      <!-- <div
        class="nothing"
        v-if="
          !list || (list && list.length == 0) || !yhqlist || ( yhqlist && yhqlist.length == 0 )
        "
      >
        <img class="nothing-img" src="@/assets/img/study/noLearn.png" />
        <div class="nothing-text">暂无优惠券哦~</div>
      </div> -->
      <div
        class="nothing"
        v-if="
          (couponType == null && orderStates == 0) && yhqlist.length == 0
        "
      >
        <img class="nothing-img" src="@/assets/img/study/noLearn.png" />
        <div class="nothing-text">暂无优惠券哦~</div>
      </div>
      <div
        class="nothing"
        v-if="
          (couponType == 1 || couponType == 2 || couponType == 3 || couponType == null) && 
          (orderStates == 0 || orderStates == 1 || orderStates == 2) && list.length == 0 && 
          (couponType !== null || orderStates !== 0)
        "
      >
        <img class="nothing-img" src="@/assets/img/study/noLearn.png" />
        <div class="nothing-text">暂无优惠券哦~</div>
      </div>
      <!-- 分页 -->
      <el-pagination
        class="tabPagination"
        :total="total"
        :current-page="pageNum"
        :page-sizes="[6, 12, 18, 24]"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        background
        layout="total, sizes, prev, pager, next"
      />
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { CouponsClient } from '@/api/mine/couponsClient'
let couponsClient = new CouponsClient()
export default {
  data() {
    return {
      userId: null, //用户信息
      orderStates: 0,
      couponType: 1,
      list: [],
      pageSize: 6,
      //订单 第几页
      pageNum: 1,
      total: 0,
      yhqlist: [],
      forPeople: [
        '',
        '指定用户',
        '全部用户',
        '新用户',
        '老用户',
        '首单优惠',
        '累计消费优惠券'
      ],
    }
  },
  created() {
    this.userId = localStorage.getItem('userId')
    // 调用获取学生订单信息接口
    this.getList() //(全部)
  },
  methods: {
    async getDiscountPcList() {
      let res = await couponsClient.getDiscountPcList(
        this.pageSize,
        this.pageNum,
        this.userId,
        ''
      )
      
        this.yhqlist = res.rows
        this.total = res.total
        res.rows.forEach(item => {
          this.$set(item, 'rules', false)
        })
      
    },
    // 标签页修改
    navChange(val, couponType) {
      this.orderStates = val
      this.couponType = couponType
      this.pageSize = 6
      this.pageNum = 1
      if (val == 0 && couponType == null) {
        this.list = []
        this.getDiscountPcList()
      } else {
        this.yhqlist = []
        this.getList()
      }
    },
    // 优惠券列表
    getList() {
      couponsClient
        .getDiscountCouponCodeListByConvertorId(
          this.pageSize,
          this.pageNum,
          this.couponType != null ? this.couponType : undefined,
          this.orderStates != null ? this.orderStates : undefined
        )
        .then((res) => {
          
            this.list = res.rows
            this.total = res.total
            res.rows.forEach(item => {
              this.$set(item, 'rules', false)
            })
          
        })
    },
    rulesOfUseBtn(item) {
      item.rules = !item.rules
    },
    handleSizeChange(val) {
      this.pageSize = val
      if (this.orderStates == 0 && this.couponType == null) {
        this.list = []
        this.getDiscountPcList()
      } else {
        this.yhqlist = []
        this.getList()
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val
      if (this.orderStates == 0 && this.couponType == null) {
        this.list = []
        this.getDiscountPcList()
      } else {
        this.yhqlist = []
        this.getList()
      }
    },

    PutMarketCode(item) {
      if (item.applicableGoods == 2) {
        Vue.prototype.goTypeDetail(
          item.applicableGoodsTypeSet,
          item.applicableGoodsSet
        )
      } else {
        this.$router.push('/')
      }
    },
    async getquan(res) {
      if (res.status == 2) {
        this.$message({
          type: 'error',
          message: '该优惠券已领取'
        })
      } else {
        await couponsClient.getUserIdCouponCode(res.id).then((res) => {
          if (res.code == 0) {
            this.$message({
              type: 'success',
              message: res.msg
            })
            this.getDiscountPcList()
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.order {
  width: 100%;
  min-height: 736px;
  // padding-bottom: 20px;
  padding: 24px 0px 20px;
  background-color: #ffffff;
  // 缩宽
  .orders {
    width: 100%;
    height: 100%;
    margin: auto;
    // 标题
    p {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #27323f;
    }
    // 跳转盒子
    .basicsName {
      width: 100%;
      height: 38px;
      padding-top: 20px;
      display: flex;
      align-items: center;
      padding: 0px 32px;
      border-bottom: 1px solid #eee;
      .basicsNames {
        /*   width: 65px; */
        height: 100%;
        margin-right: 41px;
        cursor: pointer;
        .basicsNamesTop {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
        .basicsNamesTops {
          color: #333333;
        }
        .basicsNamesCen {
          width: 24px;
          height: 2px;
          margin: 14px auto 0px;
          background: #ef0d0d;
          border-radius: 1px;
        }
      }
    }
  }
}
.tabCar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0px 32px;
  .Carlist {
    // width: 278px;
    // height: 104px;
    // background: #ffffff;
    // box-shadow: 0px 1px 6px 0px rgba(133, 133, 133, 0.16);
    border-radius: 4px;
    margin-top: 28px;
    display: flex;
    margin-right: 40px;
    .rulesOfUseBox{
      height: 40px;
      width: 340px;
      margin-top: -8px;
      .rulesOfUse{
        display: flex;
        justify-content: space-between;
        height: 40px;
        width: 340px;
        padding: 16px 8px 8px 8px;
        background-color: rgba(255, 94, 81, 0.5);
        color: #ffffff;
        font-size: 12px;
      }
    }
    
    .CarListContent {
      // width: calc(100% - 41px);
      overflow: hidden;
      position: relative;
      display: flex;
      background-image: url('~@/assets/img/user/Group 396.png');
      background-size: cover;
      .money {
        width: 96px;
        height: 106px;
        // background: #c4c4c4 linear-gradient(168deg, #ff9263 0%, #ff8177 100%);
        opacity: 1;
        font-size: 26px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #fff4bb;
        line-height: 42px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // justify-content: center;
        // border-right: 1px solid #eeeeee;
        .moneyLog {
          font-size: 16px;
          font-weight: bold;
        }
        div {
          width: 100%;
          text-align: center;
        }
        .moneys {
          margin-top: 25px;
          font-size: 28px;
          span {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular;
            font-weight: 400;
            color: #fff4bb;
            line-height: 14px;
          }
        }
        .qlis {
          font-size: 11px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #fff4bb;
          line-height: 11px;
        }
      }
      .xuxian {
        width: 1px;
        height: 100%;
      }
      .content {
        width: 224px;
        height: 106px;
        // background: linear-gradient(135deg, #fff8f4 0%, #ffebed 100%);
        opacity: 1;
        padding-left: 17px;
        .fifday {
          color: #000;
        }
        .title {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #ff5e51;
          line-height: 16px;

          margin-top: 23px;
        }
        .time {
          // width: 144px;
          // height: 11px;
          // font-size: 11px;
          // font-family: Microsoft YaHei-Regular;
          // font-weight: 400;
          // color: #ff5e51;
          // line-height: 11px;
          font-size: 11px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ff6a6f;
          line-height: 12px;
          margin-top: 6px;
        }
      }
    }
    .CarListContents {
      // width: calc(100% - 41px);
      overflow: hidden;
      position: relative;
      display: flex;
      background-image: url('~@/assets/img/user/Group 396 (1).png');
      background-size: cover;
      .money {
        width: 96px;
        height: 106px;
        // background: #c4c4c4 linear-gradient(168deg, #ff9263 0%, #ff8177 100%);
        opacity: 1;
        font-size: 26px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #ffffff;
        line-height: 42px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // justify-content: center;
        // border-right: 1px solid #eeeeee;
        .moneyLog {
          font-size: 16px;
          font-weight: bold;
        }
        div {
          width: 100%;
          text-align: center;
        }
        .moneys {
          margin-top: 25px;
          font-size: 28px;
          span {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular;
            font-weight: 400;
            color: #ffffff;
            line-height: 14px;
          }
        }
        .qlis {
          font-size: 11px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #ffffff;
          line-height: 11px;
        }
      }
      .xuxian {
        width: 1px;
        height: 100%;
      }
      .content {
        width: 244px;
        height: 106px;
        // background: linear-gradient(135deg, #fff8f4 0%, #ffebed 100%);
        opacity: 1;
        padding-left: 17px;
        .fifday {
          color: #ffffff;
        }
        .title {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #ffffff;
          line-height: 16px;

          margin-top: 23px;
        }
        .time {
          // width: 144px;
          // height: 11px;
          // font-size: 11px;
          // font-family: Microsoft YaHei-Regular;
          // font-weight: 400;
          // color: #ff5e51;
          // line-height: 11px;
          font-size: 11px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 12px;
          margin-top: 6px;
        }
      }
    }
    .CarListContentss {
      // width: calc(100% - 41px);
      overflow: hidden;
      position: relative;
      display: flex;
      background-image: url('~@/assets/img/user/Groupgq.png');
      background-size: cover;
      .money {
        width: 96px;
        height: 106px;
        // background: #c4c4c4 linear-gradient(168deg, #ff9263 0%, #ff8177 100%);
        opacity: 1;
        font-size: 26px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #ffffff;
        line-height: 42px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // justify-content: center;
        // border-right: 1px solid #eeeeee;
        .moneyLog {
          font-size: 16px;
          font-weight: bold;
        }
        div {
          width: 100%;
          text-align: center;
        }
        .moneys {
          margin-top: 25px;
          font-size: 28px;
          span {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular;
            font-weight: 400;
            color: #ffffff;
            line-height: 14px;
          }
        }
        .qlis {
          font-size: 11px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #ffffff;
          line-height: 11px;
        }
      }
      .xuxian {
        width: 1px;
        height: 100%;
      }
      .content {
        width: 244px;
        height: 106px;
        // background: linear-gradient(135deg, #fff8f4 0%, #ffebed 100%);
        opacity: 1;
        padding-left: 17px;
        .fifday {
          color: #ffffff;
        }
        .title {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #ffffff;
          line-height: 16px;

          margin-top: 23px;
        }
        .time {
          // width: 144px;
          // height: 11px;
          // font-size: 11px;
          // font-family: Microsoft YaHei-Regular;
          // font-weight: 400;
          // color: #ff5e51;
          // line-height: 11px;
          font-size: 11px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 12px;
          margin-top: 6px;
        }
      }
    }
    .contentBut {
      width: 41px;
      height: 104px;
      background: #ff6a6f;
      border-radius: 0px 4px 4px 0px;
      text-align: center;
      writing-mode: vertical-rl;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 42px;
      letter-spacing: 3px;
    }
  }
  /* 已过期 */
  .Expired {
    .CarListContent {
      .money {
        .moneyLog,
        .number {
          color: #d5d5d5 !important;
        }
      }
      .content {
        .time {
          color: #d5d5d5 !important;
        }
      }
      .contentBut {
        background: #cccccc;
      }
    }
    .CarListTip {
      color: #cccccc;
    }
  }
  /* 已使用 */
  .usered {
    .content {
      .time {
        color: #d5d5d5 !important;
      }
    }
    .contentBut {
      background: #cccccc !important;
    }
  }
}
.conflex {
  display: flex;
  justify-content: space-between;
}
.text-box {
  width: 100%;
}
.getQ {
  width: 48px;
  height: 24px;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  border: 1px solid #ff5e51;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #ff5e51;
  line-height: 24px;
  text-align: center;
  margin-top: 41px;
  margin-right: 12px;
  cursor: pointer;
  flex-shrink: 0;
}
.til_top {
  margin-top: 34px !important;
}
.ss {
  margin-right: 40px;
}
.mantil {
  height: 104px;
  line-height: 104px;
  font-size: 24px;
  font-family: Microsoft YaHei-Bold;
  font-weight: bold;
  color: #333333;
  text-align: left;
  background-color: #f5f7f9;
}
.til_m {
  width: 150px;
  word-wrap: break-word;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.forPeople{
  font-size: 12px;
  margin-top: 2px;
  color: #ffffff;
}
.fz-14{
  font-size: 14px;
}
.mb-2{
  margin-bottom: 2px;
}
.arrow{
  margin: 10px 12px 0 0;
  cursor: pointer;
  .arrowImg{
    margin: auto;
  }
}
.arrowBox{
  display: flex;
  align-items: center;
}
</style>
